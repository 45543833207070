<template>
  <div class="filter-popup">
    <div class="filter-popup_container container">
      <div class="row mb-1 mb-md-3 mb-lg-4">
        <div
          class="col-12 col-md-8 col-lg-6 col-xl-6 col-xxl-5 d-flex justify-content-between flex-column flex-md-row"
        >
          <div class="filter-popup_select">
            <span class="filter-popup_select-label">Тип предложения</span>
            <a-select placeholder="Выберите" @change="value => field.types = value">
              <span slot="suffixIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 20.133 20.133"
                >
                  <path
                    d="M1.06 9.006l9.006 9.006 9.006-9.006"
                    fill="none"
                    stroke="#ad221f"
                    stroke-width="3"
                  />
                </svg>
              </span>
              <a-select-option
                v-for="(type, index) in types"
                :key="index"
                :value="type"
              >{{ type === "sell" ? "Продажа" : type === "buy" ? "Покупка" : type === "rent" ? "Аренда" : type === "change" ? "Обмен" : "-" }}</a-select-option>
            </a-select>
          </div>

          <div class="filter-popup_select">
            <span class="filter-popup_select-label">Тип недвижимости</span>
            <a-select placeholder="Выберите" @change="value => field.propertyTypes = value">
              <span slot="suffixIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 20.133 20.133"
                >
                  <path
                    d="M1.06 9.006l9.006 9.006 9.006-9.006"
                    fill="none"
                    stroke="#ad221f"
                    stroke-width="3"
                  />
                </svg>
              </span>
              <a-select-option
                v-for="(type, index) in propertyType"
                :key="index"
                :value="type"
              >{{ type }}</a-select-option>
              <!-- <a-select-option value="Yiminghe">yiminghe</a-select-option> -->
            </a-select>
          </div>

          <div class="filter-popup_select">
            <span class="filter-popup_select-label">Тип дома</span>
            <a-select placeholder="Выберите" @change="value => field.houseTypes = value">
              <span slot="suffixIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 20.133 20.133"
                >
                  <path
                    d="M1.06 9.006l9.006 9.006 9.006-9.006"
                    fill="none"
                    stroke="#ad221f"
                    stroke-width="3"
                  />
                </svg>
              </span>
              <a-select-option
                v-for="(type, index) in houseType"
                :key="index"
                :value="type"
              >{{ type }}</a-select-option>
              <!-- <a-select-option value="Yiminghe">yiminghe</a-select-option> -->
            </a-select>
          </div>
        </div>

        <div class="col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
          <div class="filter-popup_select">
            <span class="filter-popup_select-label">Количество комнат</span>
            <!-- <a-radio-group :options="options" @change="e => field.rooms = Number(e.target.value)" /> -->
            <div class="chekbox-collumn roww">
              <a-checkbox value="1" @change="handleChangeRoom">Одна</a-checkbox>
              <a-checkbox value="2" @change="handleChangeRoom">Две</a-checkbox>
              <a-checkbox value="3" @change="handleChangeRoom">Три</a-checkbox>
              <a-checkbox value="4" @change="handleChangeRoom">Четыре+</a-checkbox>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-2 mt-md-3 mt-lg-0">
          <div class="filter-popup_select">
            <span class="filter-popup_select-label">Цена, USD</span>
            <div class="filter-popup_input-group">
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.minPriceUsd"
                :placeholder="`от ${minPriceUsd}`"
              />
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.maxPriceUsd"
                :placeholder="`до ${maxPriceUsd}`"
              />
            </div>
          </div>
        </div>

        <div class="col-xxl-3 d-none d-xxl-flex">
          <button
            @click="submitFilter"
            class="filter-popup_button button mt-auto button--main"
          >Подобрать варианты</button>
        </div>
      </div>

      <div class="row mb-0 mb-md-3 mb-lg-4" v-if="isFilterOpen">
        <div class="col-12 col-md-3 col-xxl-2">
          <div class="filter-popup_select">
            <span class="filter-popup_select-label">Общая площадь</span>
            <div class="filter-popup_input-group">
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.minSquare"
                :placeholder="`от ${minSquare}`"
              />
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.maxSquare"
                :placeholder="`до ${maxSquare}`"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3 col-xxl-2">
          <div class="filter-popup_select">
            <span class="filter-popup_select-label">Жилая площадь</span>
            <div class="filter-popup_input-group">
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.minLivingSquare"
                :placeholder="`от ${minLivingSquare}`"
              />
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.maxLivingSquare"
                :placeholder="`до ${maxLivingSquare}`"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3 col-xxl-2">
          <div class="filter-popup_select">
            <span class="filter-popup_select-label">Площадь кухни</span>
            <div class="filter-popup_input-group">
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.minKitchenSquare"
                :placeholder="`от ${minKitchenSquare}`"
              />
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.maxKitchenSquare"
                :placeholder="`до ${maxKitchenSquare}`"
              />
            </div>
          </div>
        </div>

        <div class="offset-xl-0 offset-xxl-1 col-12 col-md-3 col-xxl-2">
          <div class="filter-popup_select">
            <span class="filter-popup_select-label">Стоимость кв.м, USD</span>
            <div class="filter-popup_input-group">
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.minPriceMUsd"
                :placeholder="`от ${minPriceMUsd}`"
              />
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.maxPriceMUsd"
                :placeholder="`до ${maxPriceMUsd}`"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-0 mb-md-5 mb-lg-4" v-if="isFilterOpen">
        <div class="col-12 col-md-3 col-xxl-2">
          <div class="filter-popup_select">
            <span class="filter-popup_select-label">Год постройки</span>
            <div class="filter-popup_input-group">
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.minYear"
                :placeholder="`от ${minYear}`"
              />
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.maxYear"
                :placeholder="`до ${maxYear}`"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3 col-xxl-2">
          <div class="filter-popup_select">
            <span class="filter-popup_select-label">Этаж</span>
            <div class="filter-popup_input-group">
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.minFloor"
                placeholder="от"
              />
              <input
                type="text"
                class="filter-popup_input"
                v-model="field.maxFloor"
                placeholder="до"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3 col-xxl-3">
          <!-- <div class="filter-popup_select filter-popup_select--center"> -->
          <!-- <div class="filter-popup_select">
            <span class="filter-popup_select-label"></span>
            <a-radio-group :options="repairs" />
          </div>-->
          <div class="filter-popup_select">
            <span class="filter-popup_select-label">&nbsp;</span>
            <div class="chekbox-collumn">
              <!-- <a-radio-group :options="repairs" @change="handleChangeDop" />
              <a-radio-group :options="repairss" />-->

              <a-checkbox value="repair" @change="handleChangeDop">Наличие ремонта</a-checkbox>
              <a-checkbox value="furniture" @change="handleChangeDop">Наличие мебели</a-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 d-flex flex-column flex-lg-row justify-content-center align-items-end">
          <div class="filter-popup_select mr-auto mr-lg-0 mb-4 mb-lg-0 d-none d-md-block d-xl-none">
            <span class="filter-popup_select-label">Внешний вид</span>
            <button
              @click="handleLayoutTablet('list')"
              :class="['tablet-layout-toggle', {'tablet-layout-toggle-active' : layoutTablet === 'list'}]"
            >
              <img src="@/assets/card-icon.svg" alt="icon" />
              Список
            </button>
            <button
              @click="handleLayoutTablet('map')"
              :class="['tablet-layout-toggle', {'tablet-layout-toggle-active' : layoutTablet === 'map'}]"
            >
              <img src="@/assets/pin-icon.svg" alt="icon" />
              Карта
            </button>
          </div>
          <button
            @click="submitFilter"
            class="filter-popup_button button button--main d-none d-md-flex d-xxl-none mb-0"
          >Подобрать варианты</button>
          <button :class="['toggle-full-filter', {'open': isFilterOpen}]" @click="toggleFilter">
            Расширенный поиск
            <img
              width="28"
              height="13"
              src="@/assets/arrow-fill-down.svg"
              alt="icon"
            />
          </button>
        </div>
      </div>

      <div class="row d-flex d-md-none mt-4">
        <div class="col-12">
          <button
            @click="submitFilter"
            class="filter-popup_button button mt-auto button--main"
          >Подобрать варианты</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const options = [
  { label: "Одна", value: "1" },
  { label: "Три", value: "3" },
  { label: "Две", value: "2" },
  { label: "Четыре +", value: "4" }
];

// const repairs = [{ label: "Наличие ремонта", value: "repair" }];
// const repairss = [{ label: "Наличие мебели", value: "furniture" }];

import axios from "axios";

import { SET_OBJECTS, TOGGLE_ACTIVE_FILTER } from "../store/mutations.js";

export default {
  name: "FilterPopup",
  props: {
    types: {
      type: Array,
      default: () => []
    },
    propertyType: {
      type: Array,
      default: () => []
    },
    houseType: {
      type: Object,
      default: () => {}
    },
    minPriceUsd: {
      type: Number,
      default: 0
    },
    maxPriceUsd: {
      type: Number,
      default: 0
    },
    minSquare: {
      type: Number,
      default: 0
    },
    maxSquare: {
      type: Number,
      default: 0
    },
    minLivingSquare: {
      type: Number,
      default: 0
    },
    maxLivingSquare: {
      type: Number,
      default: 0
    },
    minKitchenSquare: {
      type: Number,
      default: 0
    },
    maxKitchenSquare: {
      type: Number,
      default: 0
    },
    minPriceMUsd: {
      type: Number,
      default: 0
    },
    maxPriceMUsd: {
      type: Number,
      default: 0
    },
    minYear: {
      type: Number,
      default: 0
    },
    maxYear: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      isFilterOpen: false,
      options,
      // repairs,
      // repairss,
      layoutTablet: "list",
      field: {
        types: "",
        propertyTypes: "",
        houseTypes: "",
        // rooms: 0,
        room1: false,
        room2: false,
        room3: false,
        room4: false,
        minFloor: "",
        maxFloor: "",
        repair: false,
        furniture: false,
        minPriceBlr: 0,
        maxPriceBlr: 100000000000,
        minPriceMBlr: 0,
        maxPriceMBlr: 100000000000,
        // minPriceUsd: this.minPriceUsd,
        // maxPriceUsd: this.maxPriceUsd,
        // minPriceMUsd: this.minPriceMUsd,
        // maxPriceMUsd: this.maxPriceMUsd,
        // minSquare: this.minSquare,
        // maxSquare: this.maxSquare,
        // minLivingSquare: this.minLivingSquare,
        // maxLivingSquare: this.maxLivingSquare,
        // minKitchenSquare: this.minKitchenSquare,
        // maxKitchenSquare: this.maxKitchenSquare,
        // minYear: this.minYear,
        // maxYear: this.maxYear

        minPriceUsd: "",
        maxPriceUsd: "",
        minPriceMUsd: "",
        maxPriceMUsd: "",
        minSquare: "",
        maxSquare: "",
        minLivingSquare: "",
        maxLivingSquare: "",
        minKitchenSquare: "",
        maxKitchenSquare: "",
        minYear: "",
        maxYear: ""
      }
    };
  },

  methods: {
    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen;
    },

    handleChangeDop(e) {
      if (e.target.value === "repair") {
        this.field.repair = !this.field.repair;
      } else {
        this.field.furniture = !this.field.furniture;
      }
    },

    handleChangeRoom(e) {
      const val = Number(e.target.value);

      if (val === 1) {
        this.field.room1 = !this.field.room1;
      } else if (val === 2) {
        this.field.room2 = !this.field.room2;
      } else if (val === 3) {
        this.field.room3 = !this.field.room3;
      } else if (val === 4) {
        this.field.room4 = !this.field.room4;
      }
    },

    submitFilter() {
      const {
        types,
        propertyTypes,
        houseTypes,
        // rooms,
        room1,
        room2,
        room3,
        room4,
        minFloor,
        maxFloor,
        repair,
        furniture,
        minPriceBlr,
        maxPriceBlr,
        minPriceMBlr,
        maxPriceMBlr,
        minPriceUsd,
        maxPriceUsd,
        minPriceMUsd,
        maxPriceMUsd,
        minSquare,
        maxSquare,
        minLivingSquare,
        maxLivingSquare,
        minKitchenSquare,
        maxKitchenSquare,
        minYear,
        maxYear
      } = this.field;
      axios
        .get(
          `/api/objects/filter?min_price=${minPriceBlr}&max_price=${maxPriceBlr}&min_price_m2=${minPriceMBlr}&max_price_m2=${maxPriceMBlr}&min_price_usd=${minPriceUsd}&max_price_usd=${maxPriceUsd}&min_price_m2_usd=${minPriceMUsd}&max_price_m2_usd=${maxPriceMUsd}&min_square=${minSquare}&max_square=${maxSquare}&min_living_square=${minLivingSquare}&max_living_square=${maxLivingSquare}&min_kitchen_square=${minKitchenSquare}&max_kitchen_square=${maxKitchenSquare}&min_year=${minYear}&max_year=${maxYear}&types=${types}&property_types=${propertyTypes}&room1=${room1}&room2=${room2}&room3=${room3}&room4=${room4}&house_types=${houseTypes}&min_floor=${minFloor}&max_floor=${maxFloor}&repair=${repair}&furniture=${furniture}`
        )
        .then(res => {
          this.$emit("close");
          const { objects } = res.data.success;
          this.$store.commit(TOGGLE_ACTIVE_FILTER, true);
          this.$store.commit(SET_OBJECTS, { objects });
        })
        .catch(() => {
          this.$notification.error({
            message: "Уведомление",
            duration: 7000,
            description:
              "Пожалуйста проверьте правильность заполнения полей формы!"
          });
        });
    },

    handleLayoutTablet(type) {
      const content = document.querySelector(".content-layout");
      const map = document.querySelector(".map-layout");

      if (type === "list") {
        this.layoutTablet = "list";
        map.classList.remove("d-block");
        map.classList.add("d-none");
        content.classList.add("d-block");
        this.$emit("toggleMap");
      } else {
        this.layoutTablet = "map";
        content.classList.remove("d-block");
        content.classList.add("d-none");
        map.classList.add("d-block");
        this.$emit("toggleMap");
      }
    }
  }
};
</script>

<style lang="scss">
.filter-popup {
  position: absolute;
  padding-top: 85px;
  padding-bottom: 25px;
  top: 0;
  left: 8px;
  width: calc(100% - 16px);
  background-color: #f6f2ea;
  box-shadow: 0 3px 6px rgba(#000000, 0.16);
  transition: all 0.2s ease;

  @media (max-width: 767px) {
    background-color: #ffffff;
    left: 0;
    width: 100%;
  }

  @media (max-width: 575px) {
    padding-top: 60px;
  }

  > .container {
    padding-right: 25px;
    padding-left: 25px;

    @media (max-width: 767px) {
      max-width: 100%;
      margin: 0;
    }
  }

  &_select--center {
    display: flex;
    align-items: center;
    height: 100%;

    .ant-radio-group {
      @media (max-width: 767px) {
        justify-content: flex-start !important;
      }
    }

    > div {
      width: 100%;
      margin-top: 40px;

      @media (max-width: 1599px) {
        margin-top: 33px;
      }

      @media (max-width: 1199px) {
        margin-top: 20px;
      }

      @media (max-width: 767px) {
        margin-top: 0;
      }

      .ant-radio-wrapper {
        flex-basis: 50% !important;

        @media (max-width: 767px) {
          flex-basis: auto !important;
        }

        &:not(:last-of-type) {
          margin-right: 30px;
        }
      }
    }
  }

  &_select {
    flex-basis: 33.333333%;

    &:not(:last-of-type) {
      margin-right: 8px;

      @media (max-width: 767px) {
        margin-right: 0;
      }
    }

    .chekbox-collumn {
      .ant-radio-group {
        display: flex;
        flex-direction: column;

        @media (max-width: 575px) {
          flex-direction: row;
        }
      }

      &.roww {
        flex-wrap: wrap;
        flex-direction: row !important;

        @media (max-width: 575px) {
          flex-direction: column !important;
        }

        .ant-checkbox-wrapper {
          flex-basis: 50%;

          @media (max-width: 767px) {
            flex-basis: 25%;
          }
        }
      }
    }

    &-label {
      display: block;
      font-weight: 700;
      color: #555;
      font-size: 16px;
      margin-bottom: 10px;

      @media (max-width: 1199px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }

  .ant-select {
    width: 100%;
    font-size: 18px;
    letter-spacing: 0.1pt;
    font-weight: 300;

    @media (max-width: 1599px) {
      /* width: 205px; */
    }

    @media (max-width: 1199px) {
      /* width: 140px; */
      font-size: 14px;
    }

    @media (max-width: 767px) {
      /* width: 100%; */
    }

    @media (max-width: 575px) {
      font-size: 16px;
    }

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  .ant-select-selection {
    border-radius: 0;
    border: 0;
    height: 60px;
    box-shadow: 0 3px 6px rgba(#000000, 0.16);

    @media (max-width: 575px) {
      box-shadow: none;
    }

    @media (max-width: 1199px) {
      height: 45px;
    }

    @media (max-width: 767px) {
      background-color: #f6f2ea;
    }

    &:hover,
    &:focus {
      border: 0;
      box-shadow: 0 3px 6px rgba(#000000, 0.16);

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }

    &__rendered {
      margin-left: 20px;
      margin-right: 25px;
      line-height: 60px;

      @media (max-width: 1199px) {
        line-height: 45px;
      }
    }
  }

  .ant-select-arrow {
    right: 25px;
  }

  .ant-select-open .ant-select-selection {
    border: 0;
    box-shadow: 0 3px 6px rgba(#000000, 0.16);

    @media (max-width: 767px) {
      box-shadow: none;
    }
  }

  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    margin-bottom: 15px !important;

    @media (max-width: 1199px) {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 5px !important;
    }

    @media (max-width: 767px) {
      margin-bottom: 0 !important;
    }

    &:hover,
    &:focus {
      .ant-radio-inner,
      .ant-checkbox-inner {
        border-color: lighten(#ad221f, 5%) !important;
      }
    }
  }

  .ant-radio,
  .ant-checkbox {
    &:hover,
    &:focus {
      .ant-radio-inner,
      .ant-checkbox-inner {
        border-color: lighten(#ad221f, 5%) !important;
      }
    }
  }

  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      justify-content: space-between;
    }

    .ant-radio-wrapper {
      flex-basis: 35%;
      margin: 0;

      @media (max-width: 767px) {
        flex-basis: auto;
      }
    }
  }

  .ant-radio-inner,
  .ant-checkbox-inner {
    border-radius: 2px;
    border-color: #ad221f !important;
    background-color: transparent !important;

    @media (max-width: 1199px) {
      width: 13px;
      height: 13px;
    }

    &::after {
      border-radius: 2px;
      background-color: #ad221f !important;

      @media (max-width: 575px) {
        top: 2px;
        left: 2px;
      }
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #ad221f;
    }
    &::after {
      border-radius: 2px;
      border-color: #ad221f;
    }
  }

  .ant-checkbox-checked {
    &::after {
      border-radius: 2px;
      border-color: #ad221f;
    }

    .ant-checkbox-inner {
      border-color: #ad221f;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    transform: translate(-50%, -50%) !important;
    border: 0 !important;
  }

  .ant-checkbox-inner {
    &::after {
      width: 7px;
      height: 7px;
      top: 50%;
      border: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .ant-checkbox-input {
    &:focus {
    }
  }

  .chekbox-collumn {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  &_input-group {
    display: flex;
  }

  &_input {
    width: 100%;
    height: 60px;
    border: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px 0 25px;
    box-shadow: 0 3px 6px rgba(#000000, 0.16);

    @media (max-width: 1199px) {
      /* width: 70px; */
      padding-left: 12px;
      height: 45px;
    }

    @media (max-width: 767px) {
      background-color: #f6f2ea;
      width: 50%;
      font-weight: 300;
    }

    @media (max-width: 767px) {
      box-shadow: none;
    }

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    &::placeholder {
      color: #bfbfbf;
    }
  }

  &_button {
    margin-bottom: 11px !important;

    @media (max-width: 1599px) {
      max-width: 310px;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      margin-left: auto;
      margin-right: -275px;
    }

    @media (max-width: 1199px) {
      margin-right: auto;
      margin-left: auto;
    }

    @media (max-width: 767px) {
      max-width: 100%;
      /* margin-bottom: 0; */
    }
  }

  &_select {
    @media (max-width: 767px) {
      margin-bottom: 22px;
    }
  }
}

.toggle-full-filter {
  margin-top: 30px;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 21px;
  color: #ad221f;
  letter-spacing: 0.2pt;

  @media (max-width: 1599px) {
    margin-top: 0;
    height: 40px;
    margin-left: auto;
  }

  @media (max-width: 991px) {
    margin-top: 0px;
    font-size: 18px;
    margin-right: auto;
  }

  @media (max-width: 575px) {
    margin-top: 35px;
    font-size: 16px;
  }

  &:hover {
    color: lighten(#ad221f, 5%);
  }

  img {
    margin-left: 20px;
    transition: transform 0.2s ease;

    @media (max-width: 1599px) {
      width: 19px;
      height: 9px;
    }
  }

  &.open {
    img {
      transform: rotate(180deg);
    }
  }
}

.ant-select-dropdown {
  border-radius: 0 !important;

  .ant-select-dropdown-menu-item:first-child,
  .ant-select-dropdown-menu-item:last-child {
    border-radius: 0;
  }

  .ant-select-dropdown-menu-item {
    color: #000000;
    font-weight: 300 !important;
    font-size: 17px;
    padding: 10px 12px;

    @media (max-width: 1199px) {
      font-size: 14px;
    }

    @media (max-width: 767px) {
      background-color: #f6f2ea;
    }

    &:hover {
      background-color: #ad221f;
      color: #ffffff;
    }

    &-active {
      background-color: #ad221f;
      color: #ffffff;
    }
  }
}

.tablet-layout-toggle {
  width: 110px;
  height: 32px;
  font-size: 11px;
  color: #555555;
  background-color: transparent;
  border: 1px solid #afafaf;

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  img {
    margin-right: 4px;
  }

  &-active {
    background-color: #ffffff;
    border: 0;
  }
}
</style>