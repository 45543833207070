<template>
  <a :href="`/objects/${slug}`" class="obgect-card obgect-card--large">
    <div
      class="obg-card_label"
    >{{ smallTitle && smallTitle.length ? smallTitle : `${label}-комнатная квартира` }}</div>
    <!-- Слайдер -->
    <div class="obgect-card_preview swiper-container">
      <div class="obgect-card_preview-wrapper swiper-wrapper">
        <!-- Слайды -->
        <div
          v-for="(image, index) in images"
          :key="index"
          class="obgect-card_preview-slide swiper-slide"
        >
          <img :src="`/storage/${image}`" :alt="name" />
        </div>
      </div>

      <button class="obgect-card_arrow obgect-card_arrow-prev">
        <svg width="14" height="14">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="@/assets/sprite-svg.svg#arrow-left"
          />
        </svg>
      </button>
      <button class="obgect-card_arrow obgect-card_arrow-next">
        <svg width="14" height="14">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="@/assets/sprite-svg.svg#arrow-right"
          />
        </svg>
      </button>
    </div>

    <!-- Описание -->
    <div class="obgect-card_desc">
      <h3 class="obgect-card_title">{{ name }}</h3>
      <span class="obgect-card_address">
        <svg width="15" height="15">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="@/assets/sprite-svg.svg#pin" />
        </svg>
        {{ address }}
      </span>
    </div>
  </a>
</template>

<script>
import Swiper from "swiper";
// import "swiper/dist/css/swiper.css";
// import "swiper/dist/js/swiper.js";

export default {
  name: "OjectCard",

  props: {
    name: {
      type: String,
      default: ""
    },

    address: {
      type: String,
      default: ""
    },

    slug: {
      type: String,
      default: ""
    },

    images: {
      type: Array,
      default: () => []
    },

    label: {
      type: Number,
      default: 0
    },

    smallTitle: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      mySwiper: null
    };
  },

  mounted() {
    this.initSwiper();
  },

  methods: {
    initSwiper() {
      this.mySwiper = new Swiper(".obgect-card_preview.swiper-container", {
        grabCursor: true,
        loop: true,
        navigation: {
          nextEl: ".obgect-card_arrow-next",
          prevEl: ".obgect-card_arrow-prev"
        }
      });
      // this.mySwiper.init();
    }
  }
};
</script>

<style lang="scss">
.obgect-card {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: block;
  box-shadow: 0 3px 6px rgba(#000000, 0.16);
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 6px 9px rgba(#000000, 0.16);

    .obgect-card_title {
      text-decoration: underline;
    }
  }

  &--large {
    max-width: 415px;

    @media (max-width: 576px) {
      max-width: 310px;
    }
  }

  &_label {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 2;
    font-family: Segoe UI, "San Francisco", -apple-system, BlinkMacSystemFont,
      "Roboto";
    font-weight: 300;
    font-size: 12px;
    color: #ad221f;
    text-transform: uppercase;
    background-color: rgba(#ffffff, 0.7);
    padding: 1.5px 5px 1.5px 15px;
  }

  &_preview {
    position: relative;
  }

  &_preview-slide {
    height: 140px !important;

    @media (max-width: 576px) {
      height: 200px !important;
    }

    img {
      object-fit: cover;
      object-position: center;
      font-family: "object-fit: cover; object-position: center;";
      max-width: none;
      width: 100% !important;
      height: 100% !important;

      @media (max-width: 576px) {
        height: 200px;
      }
    }
  }

  &_arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
    margin-top: -7px;
    padding: 0;
    background-color: transparent;
    border: 0;
    transition: all 0.2s ease;

    &-next {
      right: 10px;

      &:hover {
        transform: translateX(3px);
      }
    }

    &-prev {
      left: 10px;

      &:hover {
        transform: translateX(-3px);
      }
    }
  }

  &_desc {
    padding: 10px 20px;
    background-color: #ffffff;

    @media (max-width: 992px) {
      padding: 8px 16px;
    }
  }

  &_title {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    /* font-family: Segoe UI, "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial",
      sans-serif; */
  }

  &_address {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 10px;
    color: #555555;
    line-height: 12px;
    letter-spacing: 0.1pt;

    @media (max-width: 992px) {
      margin-top: 7px;
      font-size: 12px;
    }

    svg,
    img {
      fill: #ad221f;
      margin-right: 5px;

      @media (max-width: 992px) {
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>