import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

import {
  SET_OBJECTS,
  SET_FILTERS,
  SET_LOADING,
  TOGGLE_ACTIVE_FILTER
} from "./mutations.js";

const store = new Vuex.Store({
  state: {
    objectsLoad: false,
    filterActive: false,
    objects: [],
    fliters: {}
  },

  actions: {
    getObjects: ({ commit }) => {
      // axios.get("http://realty.baybay-dev.ru/api/objects/buy").then(res => {
      axios.get(window.way).then(res => {
        const { objects, fliters } = res.data.success;

        commit(SET_FILTERS, {
          fliters
        });

        commit(SET_OBJECTS, {
          objects
        });
      });
    }
  },

  mutations: {
    [SET_OBJECTS]: (state, { objects }) => {
      state.objects = objects;
      state.objectsLoad = true;
    },

    [SET_FILTERS]: (state, { fliters }) => {
      state.fliters = fliters;
    },

    [SET_LOADING]: state => {
      state.objectsLoad = false;
    },

    [TOGGLE_ACTIVE_FILTER]: (state, payload) => {
      state.filterActive = payload;
    }
  }
});

export default store;
