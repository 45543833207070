<template>
  <button class="close-button" @click="closeFilter"></button>
</template>

<script>
export default {
  name: "CloseButton",

  methods: {
    closeFilter() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.close-button {
  position: relative;
  width: 17px;
  height: 17px;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-left: auto;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 2px;
    background: #ad221f;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
</style>