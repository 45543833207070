import Vue from "vue";
import App from "./App.vue";

import {
  Select,
  Radio,
  Checkbox,
  notification
} from "ant-design-vue";

import store from "./store/index.js";

Vue.config.productionTip = false;

Vue.use(Select);
Vue.use(Checkbox);
Vue.use(Radio);

Vue.prototype.$notification = notification;

const settings = {
  apiKey: '0c615af4-d453-4a88-986b-7bf18ed04914',
  lang: 'ru_RU',
  coordorder: [53.893009, 27.567444],
  version: '2.1'
}

import YmapPlugin from 'vue-yandex-maps'

Vue.use(YmapPlugin, settings)

new Vue({
  render: h => h(App),
  store
}).$mount("#app");