<template>
  <button :class="['filter-button', {'open': isOpen}]" @click="handleClick">
    <img width="23" height="23" src="@/assets/filter-icon.svg" alt="icon" />
    <span>Фильтр подбора недвижимости</span>
    <img
      v-show="!isOpen"
      class="filter-button-arrow"
      src="@/assets/app-chevrone-right.svg"
      alt="icon"
    />
    <img
      v-show="isOpen"
      class="filter-button-close"
      width="23"
      height="23"
      src="@/assets/close-icon.svg"
      alt="icon"
    />
  </button>
</template>

<script>
export default {
  name: "FilterButton",
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-button {
  position: relative;
  border: 0;
  padding: 0 30px;
  height: 60px;
  background-color: #f6f2ea;
  box-shadow: 0 3px 6px rgba(#000000, 0.16);
  display: flex;
  align-items: center;
  transition: box-shadow 0.2s ease;
  z-index: 1;
  line-height: 1;

  &-arrow {
    transition: all 0.15s ease;
    // margin-top: -3px;
  }

  &-close {
    transition: all 0.15s ease;
    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: 1200px) {
    height: 45px;
    padding: 0 20px;
  }

  &:hover {
    box-shadow: 0 6px 9px rgba(#000000, 0.16);
  }

  &.open {
    box-shadow: none;

    &:hover {
      box-shadow: 0 6px 9px rgba(#000000, 0.16);
    }
  }

  img {
    @media (max-width: 1200px) {
      width: 18px;
      height: 18px;
    }
  }

  span {
    font-size: 28px;
    font-weight: 400;
    color: #694841;
    margin-left: 30px;
    margin-right: 30px;

    @media (max-width: 1200px) {
      font-size: 21px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
</style>