<template>
  <div id="app" class="app">
    <img src="@/assets/app-ornament.svg" alt="ornament" class="ornament" />
    <div class="app_filter-container container">
      <div class="row">
        <div class="app_filter-col col-12 d-flex justify-content-center align-items-center">
          <FilterButton class="d-none d-md-flex" :isOpen="isFilterOpen" @click="toggleFilter"></FilterButton>
          <button
            v-if="filterActive"
            class="clear-filter d-none d-md-block"
            @click="clearFilter"
          >Сбросить</button>

          <FilterButtonMobile
            class="d-flex d-md-none"
            :isOpen="isFilterOpen"
            @toggleFilter="toggleFilter"
            @toggleMap="toggleMapViewPort"
          ></FilterButtonMobile>

          <!-- <button class="clear-filter">Сбросить</button> -->

          <FilterPopup
            v-show="isFilterOpen"
            :types="fliters.types"
            :propertyType="fliters.property_types"
            :houseType="fliters.house_types"
            :minPriceUsd="fliters.min_price_usd"
            :maxPriceUsd="fliters.max_price_usd"
            :minSquare="fliters.min_square"
            :maxSquare="fliters.max_square"
            :minLivingSquare="fliters.min_living_square"
            :maxLivingSquare="fliters.max_living_square"
            :minKitchenSquare="fliters.min_kitchen_square"
            :maxKitchenSquare="fliters.max_kitchen_square"
            :minPriceMUsd="fliters.min_price_m2_usd"
            :maxPriceMUsd="fliters.max_price_m2_usd"
            :minYear="fliters.min_year"
            :maxYear="fliters.max_year"
            @close="toggleFilter"
            @toggleMap="toggleMapViewPort"
          ></FilterPopup>
        </div>
      </div>
    </div>

    <div class="object-card-container container">
      <div class="row mb-4">
        <div class="offset-xl-6 col-xl-6 d-flex justify-content-center">
          <div class="desktop-layout-toggler d-none d-xl-flex">
            <button
              :class="['desktop-layout-toggler-button', {'desktop-layout-toggler-active' : layoutDesktop === 'all'}]"
              @click="changeLayoutDesktop('all')"
            >Плитка с картой</button>

            <button
              :class="['desktop-layout-toggler-button', {'desktop-layout-toggler-active' : layoutDesktop === 'map'}]"
              @click="changeLayoutDesktop('map')"
            >Карта</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="content-layout col-xl-6">
          <h2
            class="app_tab-form-title d-none d-xl-block"
          >Продавайте с нами квартиры по выгодной цене</h2>

          <form class="app_tab-form tab-form_form d-none d-xl-block">
            <div class="tab-form_radio-container">
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="sale-Checlbox"
                  name="form-type"
                  value="sell"
                  v-model="field.types"
                  checked
                />
                <label class="custom-control-label" for="sale-Checlbox">Продать</label>
              </div>

              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="purchase-Checlbox"
                  name="form-type"
                  value="buy"
                  v-model="field.types"
                />
                <label class="custom-control-label" for="purchase-Checlbox">Купить</label>
              </div>

              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="exchange-Checlbox"
                  name="form-type"
                  value="rent"
                  v-model="field.types"
                />
                <label class="custom-control-label" for="exchange-Checlbox">Обменять</label>
              </div>
            </div>

            <div class="row">
              <div class="col-12 d-flex justify-content-md-between flex-column flex-md-row">
                <input type="text" name="name" v-model="field.name" placeholder="Ваше Имя" />

                <input
                  type="text"
                  name="tel"
                  ref="fieldTel"
                  v-model="field.tel"
                  placeholder="Телефон"
                />

                <button
                  class="tab-form_button button button--main"
                  @click.prevent="submitForm"
                >Оставить заявку</button>
              </div>
            </div>
          </form>

          <div class="object-card-container-scroll">
            <div>
              <div v-if="!objectsLoad" class="object-card-row row">
                <span class="preloader">
                  <img src="@/assets/preloader.svg" width="54" height="54" alt="preloader" />
                </span>
              </div>

              <div v-if="objectsLoad && !objects.length" class="object-card-row row">
                <span class="preloader">Недвижимость не найдена</span>
              </div>

              <div v-else class="object-card-row row">
                <div v-for="object in objects" :key="object.id" class="col-sm-6 col-lg-4 mb-3">
                  <OjectCard
                    :name="object.name"
                    :address="object.address"
                    :slug="object.slug"
                    :images="JSON.parse(object.images)"
                    :label="object.rooms"
                    :smallTitle="object.small_title"
                  ></OjectCard>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="map-layout col-xl-6 d-none d-xl-block position-relative">
          <!-- <div id="map" class="map"></div> -->
          <yandex-map
            :ymap-class="'map'"
            :coords="coords"
            :zoom="12"
            @map-was-initialized="initMapInst"
          >
            <ymap-marker
              v-for="object in objects"
              :key="object.id"
              :marker-id="object.id"
              :coords="[object.coordinates[0].lat, object.coordinates[0].lng]"
              :balloon-template="balloonTemplate(object)"
              :options="{ hideIconOnBalloonOpen: false }"
              :icon="{
            layout: 'default#imageWithContent',
            imageHref: Pin,
            imageSize: [33, 40],
            imageOffset: [-16.5, -20],
            content: object.neighbors.length,
            contentOffset: [-8, 9.25],
            contentLayout: `<div style='width: 50px; color: #000000; font-weight: bold;'>$[properties.iconContent]</div>`
            }"
              :callbacks="{click(){initMapHandler(object.id)}}"
            />
          </yandex-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterButton from "@/components/FilterButton.vue";
import FilterButtonMobile from "@/components/FilterButtonMobile.vue";
import FilterPopup from "@/components/FilterPopup.vue";
import OjectCard from "@/components/OjectCard.vue";

import svg4everybody from "svg4everybody";
import axios from "axios";
import Swiper from "swiper";
import Inputmask from "inputmask";

import { mapState } from "vuex";
import { SET_LOADING, TOGGLE_ACTIVE_FILTER } from "./store/mutations.js";

import Pin from "./assets/pin.svg";

import nextt from "./assets/nextt.png";
import prevv from "./assets/prevv.png";

export default {
  name: "app",
  components: {
    FilterButton,
    FilterButtonMobile,
    FilterPopup,
    OjectCard
  },

  data() {
    return {
      isFilterOpen: false,
      layoutDesktop: "all",
      mapInst: null,
      field: {
        name: "",
        tel: "",
        types: ""
      },
      mapId: null,
      Pin: Pin,
      balloonSwiper: null,
      balloonContent: {
        images: []
      },
      coords: [53.893009, 27.567444]
    };
  },

  computed: {
    ...mapState(["objects", "fliters", "objectsLoad", "filterActive"])
  },

  mounted() {
    svg4everybody();
    this.$store.dispatch("getObjects");
    const im = new Inputmask("+375 (99) 999 99 99");
    im.mask(this.$refs.fieldTel);
  },

  methods: {
    balloonTemplate(object) {
      const balloonObjects = this.objects.filter(item =>
        object.neighbors.includes(item.id)
      );

      return `
      <div class="tab-content" id="ballloonTabContent">
      ${balloonObjects
        .map(
          (objectItem, index) =>
            `<a href="/objects/${
              objectItem.slug
            }" class="ballloon-wrapper tab-pane fade ${
              index === 0 ? "show" : ""
            } ${index === 0 ? "active" : ""}" id="home${index}">
        <div class="obg-card_label">${objectItem.small_title}</div>
          <div class="swiper-container ballloon-swiper">
            <div class="swiper-wrapper">
              ${JSON.parse(objectItem.images)
                .map(
                  image =>
                    `<div class="swiper-slide" style="background-image:url(/storage/${image}"></div>`
                )
                .join("")}
            </div>

            <!-- Add Arrows -->
            <div class="swiper-button-nextt obgect-card_arrow obgect-card_arrow-next">
              <img src="${prevv}" width="14" height="14" alt="arrow">
            </div>
            <div class="swiper-button-prevv obgect-card_arrow obgect-card_arrow-prev">
              <img src="${nextt}" width="14" height="14" alt="arrow">
            </div>
          </div>
          <div class="obgect-card_desc">
            <h3 class="obgect-card_title">${objectItem.name}</h3>
            <span class="obgect-card_address">
              <img src="${Pin}" width="11" height="11">
              ${objectItem.address}
            </span>
          </div>
        </a>`
        )
        .join("")}
      </div>
      <ul class="nav nav-tabs" id="ballloonTabNav" role="tablist">
        ${balloonObjects
          .map(
            (item, index) =>
              `<li class="nav-item">
          <a class="nav-link ${
            index === 0 ? "active" : ""
          }" id="home${index}-tab" data-toggle="tab" href="#home${index}">${index +
                1}</a>
        </li>`
          )
          .join("")}
      </ul>
      `;
    },

    // <li class="nav-item">
    //   <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home">1</a>
    // </li>
    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen;
    },

    initBolloonSwiper() {
      this.balloonSwiper = new Swiper(".ballloon-swiper.swiper-container", {
        loop: true,
        navigation: {
          nextEl: ".swiper-button-nextt",
          prevEl: ".swiper-button-prevv"
        }
      });

      $('a[data-toggle="tab"]').on("shown.bs.tab", () => {
        this.balloonSwiper = null;
        this.$nextTick(() => {
          this.balloonSwiper = new Swiper(".ballloon-swiper.swiper-container", {
            loop: true,
            navigation: {
              nextEl: ".swiper-button-nextt",
              prevEl: ".swiper-button-prevv"
            }
          });
        });
      });
    },

    initMapInst(map) {
      this.mapId = map;
    },

    // @@id
    initMapHandler() {
      setTimeout(() => {
        this.initBolloonSwiper();
      }, 1000);
    },

    clearFilter() {
      this.$store.commit(SET_LOADING);
      this.$store.dispatch("getObjects");
      this.isFilterOpen = false;
      this.$store.commit(TOGGLE_ACTIVE_FILTER, false);
    },

    changeLayoutDesktop(type) {
      const content = document.querySelector(".content-layout");
      const map = document.querySelector(".map-layout");

      if (type === "all") {
        this.layoutDesktop = "all";
        content.classList.add("d-block");
        content.classList.remove("col-xl-6");
        content.classList.add("col-xl-6");
        map.classList.remove("col-xl-12");
        map.classList.add("col-xl-6");
        this.toggleMapViewPort();
      } else {
        this.layoutDesktop = "map";
        content.classList.remove("d-block");
        content.classList.add("d-none");
        map.classList.add("col-xl-12");
        this.toggleMapViewPort();
      }
    },

    submitForm() {
      const { name, tel, types } = this.field;
      axios
        .post("/api/forms ", {
          name,
          tel,
          types
        })
        .then(() => {
          this.field.name = "";
          this.field.tel = "";
          this.field.types = "";
        });
    },

    toggleMapViewPort() {
      this.mapId.container.fitToViewport();
    }
  }
};
</script>

<style lang="scss">
$font-family: "San Francisco", -apple-system, BlinkMacSystemFont, "Roboto",
  "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif;

$grid-gutter-width: 16px;
$grid-columns: 12;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  fhd: 1920px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  xxl: 1530px,
  fhd: 1720px
);

.col-xs-0,
.col-sm-0,
.col-md-0,
.col-lg-0 {
  flex: 0 0 0;
  max-width: 0;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}

body,
html {
  height: auto !important;
  min-height: 100vh;
}

.app {
  position: relative;
  width: 100%;
  height: 100vh;
  // padding: 20px 0 30px 0;
  background-color: #efe9e1;
  overflow: hidden;

  @media (max-width: 1199px) {
    height: auto;
    min-height: 100vh;
  }

  .ornament {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -45%);
    width: 495px;
    height: 495px;
  }

  &_filter-container {
    position: relative;
    z-index: 10;
    margin-bottom: 30px;

    @media (max-width: 1199px) {
      max-width: 100% !important;
    }
  }

  &_filter-col {
    @media (max-width: 768px) {
      padding: 0 !important;
    }
  }

  &_tab-form {
    margin-top: 30px;
    margin-bottom: 40px;

    .tab-form_radio-container {
      margin-bottom: 20px;
    }

    input {
      flex-basis: 50%;
      margin-right: 8px;
      padding-left: 20px;
      border: 0 !important;
      box-shadow: 0 3px 6px rgba(#000000, 0.16);
    }

    .tab-form_button {
      max-width: 270px;
      box-shadow: 0 3px 6px rgba(#000000, 0.16);

      @media (max-width: 1599px) {
        font-size: 12px;
      }
    }

    &-title {
      font-size: 28px;
      color: #ad221f;
      font-weight: 700;
      line-height: 34px;
      text-align: center;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .map,
  .ymap-container {
    width: 100%;
    height: 100%;

    @media (max-width: 1199px) {
      height: 600px;
    }
  }

  .marker {
    width: 33px;
    height: 40px;
    background-image: url("./assets/pin.svg");
  }

  .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
  }

  .clear-filter {
    background-color: transparent;
    border: none;
  }

  .desktop-layout-toggler {
    // position: absolute;
    align-items: center;
    // top: 10px;
    // left: 50%;
    // transform: translateX(-50%);
    z-index: 2;

    &-button {
      display: inline-block;
      width: 270px;
      height: 36px;
      padding: 0;
      border: none;
      color: #afafaf;
      transition: all 0.2s ease;
      background-color: rgba(#ffffff, 0.85);
      box-shadow: 0 3px 6px rgba(#000000, 0.16);

      &:hover {
        color: #949494;
        background-color: rgba(#ffffff, 1);
      }

      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }

    &-active {
      background-color: rgba(#694841, 0.85);
      color: #ffffff;

      &:hover {
        color: #ffffff;
        background-color: rgba(#694841, 1);
      }
    }
  }
}

.object-card-container {
  &-scroll {
    position: relative;
    height: calc(100vh - 395px);
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 15px;

      @media (max-width: 768px) {
        width: 0px;
      }
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #ad221f;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(#ad221f, 0.8);
    }

    @media (max-width: 1200px) {
      height: auto;
    }

    > div {
      overflow: hidden;
    }
  }
}

// .object-card_container {
//   height: calc(100vh - 85px);
// }

// .object-card_col {
//   height: 100%;
//   overflow: scroll;
// }

.app_filter-button {
  display: flex;
  justify-content: center;
}

.clear-filter {
  position: relative;
  font-size: 21px;
  letter-spacing: 0.25pt;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-left: 20px;
  color: #ad221f;
  transition: color 0.15s ease;
  z-index: 1;

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  &:hover {
    color: lighten(#ad221f, 5%);
  }
}

.ant-notification-notice {
  border-radius: 0;
}

.ant-notification-notice-icon-error {
  color: #ad221f;
}

.ymaps-2-1-74-balloon__tail,
.ymaps-2-1-74-balloon__close {
  display: none !important;
}

.ymaps-2-1-74-balloon__content {
  margin-right: 0 !important;
  padding: 0 !important;
}

.ymaps-2-1-74-balloon {
  top: -260px !important;
  left: -100px !important;
}

.ballloon-swiper {
  display: flex;
  width: 270px;
  height: 150px;

  .swiper-slide {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.ballloon-wrapper {
  display: block;
  width: 270px;
  height: auto;
  position: relative;

  &:hover {
    .obgect-card_title {
      text-decoration: underline;
    }
  }

  .obgect-card_desc {
    width: 270px;
    box-sizing: border-box;
  }

  .obg-card_label {
    padding-top: 0;
    padding-bottom: 0;
  }
}

#ballloonTabNav {
  margin-top: 0;
  width: 270px !important;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0 !important;

  .nav-item {
    flex-basis: 100%;
    margin-bottom: 0 !important;
  }

  .nav-link {
    position: relative;
    border: none;
    text-align: center;
    padding: 0;
    padding-bottom: 8px;
    color: #555555;

    &.active {
      &::after {
        height: 6px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: #ad221f;
      transition: all 0.2s ease;
    }
  }
}
</style>
