<template>
  <div :class="['filter-mobile', {'filter-popup-open': isOpen}]">
    <button class="filter-button-mobile" @click="toggleFilter">
      <img src="@/assets/filter-icon2.svg" alt="icon" />
      Фильтр
    </button>

    <button
      @click="handleLayoutTablet('list')"
      v-if="!isOpen"
      class="filter-button-mobile ml-auto mr-2"
    >
      <img src="@/assets/card-icon.svg" alt="icon" />
      Список
    </button>

    <button @click="handleLayoutTablet('map')" v-if="!isOpen" class="filter-button-mobile mr-auto">
      <img src="@/assets/pin-icon.svg" alt="icon" />
      Карта
    </button>

    <CloseButton v-if="isOpen" @close="toggleFilter"></CloseButton>

    <a
      v-if="!isOpen"
      href="#obj-form"
      data-scroll-link="obj-form"
      class="button button--main"
    >Оставить заявку</a>
  </div>
</template>

<script>
import CloseButton from "@/components/CloseButton.vue";

export default {
  name: "FilterButtonMobile",

  components: {
    CloseButton
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggleFilter() {
      this.$emit("toggleFilter");
    },

    handleLayoutTablet(type) {
      const content = document.querySelector(".content-layout");
      const map = document.querySelector(".map-layout");

      if (type === "list") {
        map.classList.remove("d-block");
        map.classList.add("d-none");
        content.classList.add("d-block");
        this.$emit("toggleMap");
      } else {
        content.classList.remove("d-block");
        content.classList.add("d-none");
        map.classList.add("d-block");
        this.$emit("toggleMap");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-mobile {
  height: 40px;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 0 25px;

  @media (max-width: 350px) {
    padding: 0 15px;
  }

  &.filter-popup-open {
    z-index: 2;
  }

  .button {
    font-size: 10px;
    width: auto;
    padding: 0 5px;
    height: 20px;
    text-transform: initial;

    @media (max-width: 350px) {
      padding: 0 2px;
      font-size: 9px;
    }
  }
}

.filter-button-mobile {
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 13px;
  color: #555555;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  background: transparent;

  @media (max-width: 576px) {
    font-size: 12px;
  }

  @media (max-width: 350px) {
    font-size: 11px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 10px;

    @media (max-width: 576px) {
      width: 12px;
      height: 12px;
    }

    @media (max-width: 350px) {
      width: 11px;
      height: 11px;
    }
  }
}
</style>